import React, { useState } from "react"
import M from "moment"
import { GetBlogEventsPosts } from "../graphql/queries"
import "../styles/blog.css"
import Layout from "../components/layout"
import BlogCard from "../components/BlogCard"
import Filter from "../components/Filter"

const Blog = () => {
    const data = GetBlogEventsPosts()
    const { edges } = data.allContentfulBlogPost
    const [dateFilter, setDateFilter] = useState("")
    const [catFilter, setCatFilter] = useState("")

    const [openedFilter, setOpenedFilter] = useState(null)

    const getFilterData = filterBy => {
        const filterArr = ["all"]

        for (const field of edges) {
            const { node } = field

            if (filterBy === "createdAt") {
                const date = node[filterBy]
                const stringDate = M(date).format("MMMM YYYY")
                if (!filterArr.includes(stringDate)) {
                    filterArr.push(stringDate)
                }
            } else if (node[filterBy]) {
                if (!filterArr.includes(node[filterBy])) {
                    filterArr.push(node[filterBy])
                }
            }
        }

        return filterArr
    }

    const onSelection = (option, setFunc) => {
        if (option === "all") {
            setFunc("")
        } else {
            setFunc(option)
        }

        setOpenedFilter("")
    }

    const onOpenFilter = text => {
        if (!openedFilter) {
            setOpenedFilter(text)
        }
    }

    const onCloseFilter = () => {
        if (openedFilter) {
            setOpenedFilter(null)
        }
    }

    return (
        edges !== null && (
            <Layout withStrokes onBodyClick={() => onCloseFilter()}>
                <div className="blog--main-wrapper">
                    <div className="blog-filters--wrapper">
                        <Filter
                            text="Archives"
                            options={getFilterData("createdAt")}
                            selected={dateFilter || "all"}
                            onSelection={opt => onSelection(opt, setDateFilter)}
                            isOpened={"Archives" === openedFilter}
                            setIsOpened={onOpenFilter}
                        />
                        <Filter
                            text="Categories"
                            options={getFilterData("tag")}
                            selected={catFilter || "all"}
                            onSelection={opt => onSelection(opt, setCatFilter)}
                            isOpened={"Categories" === openedFilter}
                            setIsOpened={onOpenFilter}
                        />
                    </div>
                    <div className="blog-cards--wrapper">
                        {edges
                            .filter(({ node }) => {
                                if (catFilter) {
                                    return node.tag === catFilter
                                }
                                return node
                            })
                            .filter(({ node }) => {
                                if (dateFilter) {
                                    return (
                                        M(node.createdAt).format(
                                            "MMMM YYYY"
                                        ) === dateFilter
                                    )
                                }
                                return node
                            })
                            .map(({ node }) => (
                                <BlogCard
                                    data={node}
                                    key={node.id}
                                    toPath={`/blog/${node.id}`}
                                />
                            ))}
                    </div>
                </div>
            </Layout>
        )
    )
}

export default Blog
