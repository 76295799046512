import "./style.css"

import { Link } from "gatsby"
import M from "moment"
import React from "react"

const BlogCard = ({ data, toPath, isEvent }) => {
    const hasTag = data.tag || data.category
    return (
        <Link to={toPath} className="card-blog--wrapper">
            <div
                className="card-blog--wrapper-image"
                style={{
                    backgroundImage: `url(${data?.posterImage?.file?.url}?fit=scale&w=300&fm=jpg)`
                }}
            />
            <div className="card-blog--wrapper-content">
                <div>
                    <p className="card-blog--wrapper-content-title">
                        {data?.title}
                    </p>
                    <p className="card-blog--wrapper-content-by">
                        {isEvent
                            ? `Location: ${data.location}`
                            : `Article written by ${data?.author}`}
                    </p>
                    <p className="card-blog--wrapper-content-date">
                        {isEvent
                            ? M(data?.eventDate).format("Do MMMM YYYY")
                            : M(data?.createdAt).format("Do MMMM YYYY")}

                        {data?.eventDateEnd &&
                            ` - ${M(data?.eventDateEnd).format(
                                "Do MMMM YYYY"
                            )}`}
                    </p>
                    <p className="card-blog--wrapper-content-desc">
                        {data?.description}
                    </p>
                </div>

                <div className={`card-blog--bottom ${hasTag && "add-space"}`}>
                    {hasTag && (
                        <span className="card-blog--tag">
                            {isEvent ? data.category : data.tag}
                        </span>
                    )}
                    <span className="card-blog--wrapper-content-more">
                        Read more
                    </span>
                </div>
            </div>
        </Link>
    )
}

// Generics
// If the image is 128 pixels wide and 64 pixels high, what is the size in memory of the data?

export default BlogCard
