import React from "react"
import Arrow from "../../../static/svg/arrow.svg"
import "./style.css"

const Filter = ({
    text,
    options,
    selected,
    onSelection,
    isOpened,
    setIsOpened,
    setShowPastEvents,
    showPastEvents
}) => {
    const onModalSelect = option => {
        onSelection(option)
        if (text === "Categories") {
            setShowPastEvents(false)
        }
        setIsOpened(null)
    }

    return (
        <div className="filter-wrapper" onClick={() => setIsOpened(text)}>
            <div className="filter--text">
                <div className="filter-column">
                    <p>{text}</p>
                    {!showPastEvents ? (
                        <p className="filter-column-subText">
                            {selected !== "all" && selected}
                        </p>
                    ) : (
                        <p className="filter-column-subText">Past events</p>
                    )}
                </div>
                <img src={Arrow} alt="arrow-icon" />
            </div>
            {isOpened && (
                <div className="filter--options-wrapper">
                    {options.map(option => (
                        <p
                            key={option}
                            onClick={() => onModalSelect(option)}
                            className={`filter--options-wrapper-option ${
                                selected === option && "isSelected"
                            }`}
                        >
                            {option}
                        </p>
                    ))}
                    {text === "Categories" && (
                        <p
                            onClick={() => setShowPastEvents(true)}
                            className={`filter--options-wrapper-option ${
                                showPastEvents && "isSelected"
                            }`}
                        >
                            Past events
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default Filter
