import { graphql, useStaticQuery } from "gatsby"

export const GetBlogEventsPosts = () => {
    const { allContentfulBlogPost, allContentfulEventPost } = useStaticQuery(
        graphql`
            query {
                allContentfulBlogPost {
                    totalCount
                    edges {
                        node {
                            id
                            title
                            tag
                            posterImage {
                                file {
                                    url
                                }
                            }
                            description
                            author
                            createdAt
                        }
                    }
                }
                allContentfulEventPost {
                    edges {
                        node {
                            id
                            createdAt
                            description
                            category
                            location
                            title
                            eventDate
                            eventDateEnd
                            posterImage {
                                file {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return { allContentfulBlogPost, allContentfulEventPost }
}
